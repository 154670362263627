<template>
  <TransListLayout
    backRouteName="WalletMainParents"
    :backPage="$route.query.backPage"
    :model="viewModel.model"
    :isOptionSelectPopup="viewModel.isOptionSelectPopup"
    :emptyDesc="viewModel.emptyDesc"
    @onSearch="value => viewModel.onSearch(value)"
    @onClickDetail="transaction_uid => viewModel.onClickDetail(transaction_uid)"
    @onClickOptionSelectPopupClose="viewModel.onClickOptionSelectPopupClose()"
    @onClickOptionSelectPopupCompelete="(transTypeValue, categoryValue) => viewModel.onClickOptionSelectPopupCompelete(transTypeValue, categoryValue)">
    <template v-slot:filter_option>
      <Selectbox
        size="small"
        :title="$poData.trans_filter_month_optionsheet_title"
        :isCloseBtn="true"
        :dataList="viewModel.model.year_month_list"
        :value.sync="viewModel.model.searchData.option.transactionYearMonth"
        @update:value="viewModel.onSearch()" />
      <Selectbox
        size="small"
        title="옵션 선택"
        :isCloseBtn="true"
        :dataList="viewModel.model.option_list"
        :value.sync="viewModel.model.searchData.option.searchOption"
        @update:value="viewModel.onSearch()" />
    </template>
  </TransListLayout>
</template>

<script>
import TransListLayout from '@/views/v2/transaction/view/TransListLayout';
import Selectbox from '@/components/common/selectbox/Selectbox';
import { convertIdToText } from "@/utils/selectDataUtils";

// mixins
import InterFaceMixin from '@/mixins/InterFaceMixin';
import BackKeyEventMixin from '@/mixins/BackKeyEventMixin';
// viewModel
import TransListViewModel from '@/views/v2/transaction/viewModel/TransListViewModel'

export default {
  name: 'ParentsTransList',
  mixins:[ InterFaceMixin, BackKeyEventMixin ],
  components:{
    TransListLayout,
    Selectbox,
  },
  beforeMount(){
    this.viewModel.model.userType = 'PARENTS';
    const query = this.$route.query;
    this.viewModel.init(query);
  },
  data(){
    return{
      viewModel: new TransListViewModel(),
    }
  },
  methods:{
    convertIdToText(value, dataListName, cutLength){
      return convertIdToText(value, dataListName, cutLength)
    },
    onClickBackKey(){
      if(this.viewModel.model.searchData.isSearchOpen){
        this.viewModel.onSearchCancle();
      }else if(this.$route.query.backPage){
        this.pageMove(this.$route.query.backPage);
      }else{
        this.$router.push({ name: 'WalletMainParents' });
      }
    }
  }
}
</script>